import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { StructuredText as DatoStructuredText } from 'react-datocms';
import Youtube from 'react-youtube';
import { Box } from '@tymate/margaret';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PictureLegend, Title } from 'ui';
import Zoom from 'react-medium-image-zoom';
import ArticleCard from './ArticleCard';
import { Subtitle } from '../ui';
import { MdChevronRight } from 'react-icons/md';

const MediaWrapper = styled(Box).attrs({ marginVertical: 2 })`
  box-shadow: 0 4px 8px ${({ theme }) => theme.separator};

  ${({ variant }) =>
    variant === 'image' &&
    css`
      box-shadow: none;

      .gatsby-image-wrapper {
        box-shadow: 0 4px 8px ${({ theme }) => theme.separator};
      }
    `}
`;

const Liste = styled.ul`
  color: ${({ theme }) => theme.primary};
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.primary};

    &:hover {
      color: ${({ theme }) => theme.primaryDark};
    }
  }
`;

const ListeElement = styled.li`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

const StructuredText = ({ data, id = '0', ...props }) => {
  const theme = useTheme();
  return (
    <DatoStructuredText
      renderInlineRecord={({ record }) => (
        <Box marginVertical={2}>
          <ArticleCard article={record} variant="inline" />
        </Box>
      )}
      renderFragment={children =>
        children.map(child => {
          const { type, key, props } = child;
          switch (type) {
            case 'h1':
              return (
                <Title
                  as="h2"
                  id={`section-${id}-${key}`}
                  variant="tocable"
                  {...props}
                />
              );
            case 'h2':
              return (
                <Subtitle
                  as="h3"
                  id={`section-${id}-${key}`}
                  variant="tocable"
                  {...props}
                />
              );
            case 'ul':
              return (
                <Liste id={`section-${id}-${key}`} {...props}>
                  {props?.children?.map(child =>
                    child?.props?.children?.map(child =>
                      child?.props?.children?.map(child =>
                        child?.type === 'a' ? (
                          <ListeElement alignY="center" gutterSize={0.5}>
                            <MdChevronRight />
                            <a {...child?.props} target="_blank">
                              {child?.props?.children[0]}
                            </a>
                          </ListeElement>
                        ) : (
                          <ListeElement alignY="center" gutterSize={0.5}>
                            <MdChevronRight color={theme.text} />
                            <div style={{ color: theme.text }}>{child}</div>
                          </ListeElement>
                        ),
                      ),
                    ),
                  )}
                </Liste>
              );
            default:
              return child;
          }
        })
      }
      renderBlock={({ record }) => {
        switch (record?.__typename) {
          case 'DatoCmsPicture':
            return (
              <MediaWrapper variant="image" as="figure" marginHorizontal={0}>
                <Zoom>
                  <GatsbyImage image={record?.picture?.gatsbyImageData} />
                </Zoom>
                <PictureLegend as="figcaption">{record?.name}</PictureLegend>
              </MediaWrapper>
            );
          case 'DatoCmsVideo':
            return (
              record?.videoLink?.provider === 'youtube' && (
                <MediaWrapper>
                  <Youtube
                    videoId={record?.videoLink?.providerUid}
                    containerClassName="c-youtube-video"
                  />
                </MediaWrapper>
              )
            );
          default:
            return null;
        }
      }}
      data={data}
      {...props}
    />
  );
};

export default StructuredText;
